const messages = {
  MOBILE_NUMBER_ERROR: "Mobile Number can not be empty",
  INVALID_MOBILE_NUMBER_ERROR: "Mobile number must be valid",
  INVALID_OTP: "Invalid OTP",
  EMAIL_ERROR: "Email can not be empty",
  EMAIL_MUST_BE_VALID: "Email must be valid",
  NAME_ERROR: "Name can not be empty",
  INVALID_NAME: "Name must be valid",
  PRICE_REQURIED: "Price cannot be empty",
  ONLY_CHARACTER: "Only Character",
  ONLY_NUMBER: "Only Numbers",
  FLOAT_NUMBER:"Only Float Numbers",
  INVALID_URL: "Invalid Url",
  INVALID_AADHAAR_NUMBER: "Invalid Aadhar Number",
  INVALID_IFSC_CODE:
    "IFSC code must be Alpha Numeric and all the letter should be Capital",
  INVALID_ACCOUNT_NUMBER: "Account Number should be between 9-17 digits",
  INVALID_PAN_NUMBER: "Invalid Pan Number",
  INVALID_GST: "Invalid Pan Number",
  INVALID_CITY: "Invalid City",
  INVALID_STATE: "Invalid State",
  FIELD_REQURIED: "This Field is Required.",
  NO_REFERENCE: "Reference data not found",
  CURRENT_ADDRESS_NOT_FOUND: "Current Address not found",
  PERMANENT_ADDRESS_NOT_FOUND: "Permanent Address not found",
  EMPLOYMENT_DETAILS_NOT_FOUND: "Employment details not found",
  AADHAAR_DETAILS_NOT_FOUND: "Aadhaar details not found",
  BANK_DETAILS_NOT_FOUND: "Bank details not found",
  DOCUMENTS_NOT_FOUND: "Documents not found",
  VERIFICATION_DETAILS_NOT_FOUND: "Verification details not found",
  AMOUNT_NOT_VALID: "Amount is not valid",
  PIN_CODE: "Invalid pin code",
  TENURE_NOT_VALID: "Tenure is not valid",
};

export default messages;
