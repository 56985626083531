import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router/index";
import VueCryptojs from "vue-cryptojs";

import messages from "./app-js/messages.js";
import urls from "./app-js/urls.js";
import rules from "./app-js/rules.js";

import axios_instance from "./app-js/helper-axios.js";
import axios from "axios";

import { store } from "./store/";
// import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.prototype.$messages = messages;
Vue.prototype.$urls = urls;
Vue.prototype.$rules = rules;

Vue.config.productionTip = false;

import mixpanel from "mixpanel-browser";

mixpanel.init("bea913812e17a4a4bd10df29d01adf69", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});
// mixpanel.identify("USER_ID");

Vue.use(VueCryptojs);

let CancelToken = axios.CancelToken;
let source = CancelToken.source();
// let access_token_businessloan = sessionStorage.getItem("access_token");
// console.log("maainnn", access_token_businessloan);
Vue.mixin({
  beforeRouteEnter(to, from, next) {
    document.title = to.meta.seo_title || "Cashpo";
    // Update meta description
    const description = document.querySelector('meta[name="description"]');
    if (description && to.meta.seo_description) {
      description.setAttribute("content", to.meta.seo_description || "Cashpo");
    }
    else{
      description.setAttribute("content", "Cashpo");
    }

    next();
  },
  beforeRouteLeave: function (to, from, next) {
    // console.log("base before route leave");
    // console.log(to, "this is to");
    // console.log(to.name.toLowerCase());

    if (from.name == "Business-Loan") {
      this.$store.dispatch("business_loan_data/clearAllData");
      sessionStorage.removeItem("user_id");
      // sessionStorage.removeItem("access_token")
      sessionStorage.removeItem("profile_status");
    }
    // if (this.$route.meta.track_page == true) {
    //   mixpanel.track("Page Viewed", { "Page Name": to.meta.title });
    //   // mixpanel.track_pageview({ page: to.meta.title });
    // }

    // if (to.meta.requiresAuth == true) {
    //   let permission_list =
    //     JSON.parse(sessionStorage.getItem("permission_list")) || [];
    //   permission_list = permission_list.join(" ").toLowerCase();
    //   if (permission_list.includes(to.meta.title.toLowerCase())) next();
    //   else next(from);
    // }
    next();
  },
  computed: {
    $isDesktop() {
      return this.$vuetify.breakpoint.width >= 1000;
    },
    $isTablet() {
      const width = this.$vuetify.breakpoint.width;
      return width < 1000 && width >= 600;
    },
    $isMobile() {
      // console.log(this.$vuetify.breakpoint.width < 600);
      return this.$vuetify.breakpoint.width < 600;
    },
  },
  methods: {
    getPermission(item) {
      let permission_list =
        JSON.parse(sessionStorage.getItem("permission_list")) || [];
      if (permission_list.includes(item)) {
        return true;
      } else {
        return false;
      }
    },
    showSnackBar(type, text, timeout, can_close, show_on_top) {
      this.$store.dispatch("snackbar/setSnackbar", {
        flag: true,
        text,
        color: type,
        timeout: timeout,
        can_close: can_close,
        show_on_top: show_on_top,
      });
    },
    toggle_loading() {
      this.$store.dispatch("setPageLoader", !this.$store.state.page_loader);
    },
    handleStatusCode(response) {
      if (response.status === 401) {
        this.showSnackBar("error", "Invalid Access token", 2000, true, true);
        return true;
      } else if (response.status === 440) {
        this.showSnackBar("error", "Session Expired", 2000, true, true);
        return true;
      } else if (response.status === 404) {
        this.showSnackBar("error", "Page Not Found", 2000, true, true);
        return true;
      } else if (response.status === 403) {
        this.showSnackBar("error", "Invalid token", 2000, true, true);
        return true;
      } else if (response.status === 500) {
        this.showSnackBar("error", "Internal server error", 2000, true, true);
        return true;
      } else if (response.status === 413) {
        this.showSnackBar("error", "File size too large", 2000, true, true);
        return true;
      } else {
        return false;
      }
    },
    handle400(status_code) {
      // console.log("status_code", status_code);
      if (status_code === 400) {
        // console.log("Invalid Access token");
        this.showSnackBar("error", "Invalid Access token", 2000, true, true);
      }
    },
    handle401(status_code) {
      // console.log(status_code);
      if (status_code === 401) {
        // console.log("Invalid Access token");
        this.showSnackBar("error", "Invalid Access token", 2000, true, true);
      }
    },
    handle440(status_code) {
      if (status_code === 440) {
        console.log("Session Expired");
        this.showSnackBar("error", "Session Expired", 2000, true, true);
      }
    },
    handle404(status_code) {
      if (status_code === 404) {
        console.log("Page Not Found");
        this.showSnackBar("error", "Page Not Found", 2000, true, true);
      }
    },
    handle403(status_code) {
      if (status_code === 403) {
        console.log("Invalid token");
        this.showSnackBar("error", "Invalid token", 2000, true, true);
      }
    },
    /* handel 500 error*/
    handle500(status_code) {
      console.log(status_code);
      if (status_code === 500) {
        console.log("Internal server error");
        this.showSnackBar("error", "Internal server error", 2000, true, true);
      }
    },
    handle413(status_code) {
      console.log(status_code);
      if (status_code === 413) {
        console.log("Entity too large");
        this.showSnackBar("error", "File size too large", 2000, true, true);
      }
    },
    /* handel 500 error*/
    handleNoInternetConnection() {
      console.log("No Internet");
      this.showSnackBar(
        "error",
        "Please check your internet connection",
        2000,
        true,
        true
      );
    },

    /* common  function  for GET API call */
    request_GET: (
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) => {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }

      if (headers == null) {
        let access_token_businessloan = sessionStorage.getItem("access_token");
        let pl_token = sessionStorage.getItem("token");
        let token = access_token_businessloan
          ? access_token_businessloan
          : pl_token;
        // console.log("token", token);
        headers = {
          // token: sessionStorage.getItem("token"),
          token: token,
          "application-type": "web",
        };
      }

      axios_instance
        .get(url, {
          params: params,
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnackBar(
                "error",
                response.data.message,
                2000,
                true,
                true
              );

            fnSuccessResponse(response);
          }
        })
        .catch(function (error) {
          // self = this;
          if (error.response) {
            console.log("response is", error.response.status);
            self.handle400(error.response.status);
            self.handle401(error.response.status);
            self.handle404(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
          } else {
            if (fnFailureResponse != null) {
              fnFailureResponse(error);
            }
            // else {
            //     // self.handleNoInternetConnection()
            // }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    /* common function for the POST API Call */
    request_POST(
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let access_token_businessloan = sessionStorage.getItem("access_token");
        let pl_token = sessionStorage.getItem("token");
        let token = access_token_businessloan
          ? access_token_businessloan
          : pl_token;
        headers = {
          token: token,
          "content-type": "multipart/form-data",
          "application-type": "web",
        };
      }
      axios_instance
        .post(url, params, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnackBar(
                "error",
                response.data.message,
                2000,
                true,
                true
              );

            fnSuccessResponse(response);
          }
        })
        .catch(function (error) {
          console.log("request_POST", error);
          if (!error.response) {
            console.log(error);
          } else {
            // console.log(error.response.status);
            if (!self.handleStatusCode(error.response)) {
              self.showSnackBar(
                "error",
                error.response.data.detail,
                2000,
                true,
                true
              );
            }
            // self.handle401(error.response.status);
            // self.handle440(error.response.status);
            // self.handle403(error.response.status);
            // self.handle500(error.response.status);
            // self.handle404(error.response.status);
            // self.handleOther(error.response)
            if (fnFailureResponse != null) {
              // console.log("inside failure handler");
              fnFailureResponse(error);
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    // common function for the PUT API Call
    request_PUT(
      self,
      url,
      body,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let access_token_businessloan = sessionStorage.getItem("access_token");
        let token = access_token_businessloan;

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .put(url, body, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnackBar(
                "error",
                response.data.message,
                2000,
                true,
                true
              );

            fnSuccessResponse(response);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (!error.response) {
            console.log(error);
          } else {
            console.log(error.response.data.status_code);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle413(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },

    /* common function for the PATCH API Call */
    request_PATCH(
      self,
      url,
      params,
      body,
      fnSuccessResponse = null,
      headers = null,
      fnFailureResponse = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .patch(url + params, body, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          // console.log("response.data.success=" + response.data.success);
          if (fnSuccessResponse != null) {
            if (response.data.success == false)
              self.showSnackBar(
                "error",
                response.data.message,
                2000,
                true,
                true
              );

            fnSuccessResponse(response);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (!error.response) {
            console.log(error);
          } else {
            console.log(error.response.data.status_code);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            self.handle413(error.response.status);
            if (fnFailureResponse != null) {
              fnFailureResponse();
            }
            // else {
            //     self.handleNoInternetConnection()
            // }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    /*perform encription for Rout params */
    encript(text) {
      return this.CryptoJS.AES.encrypt(text.toString(), "wsdcvhiol").toString();
    },
    /*perform decription for Rout params*/
    decrypt(text) {
      return this.CryptoJS.AES.decrypt(text, "wsdcvhiol").toString(
        this.CryptoJS.enc.Utf8
      );
    },

    encriptLocalStore(key_name, data) {
      let stringify_data = JSON.stringify(data);
      localStorage.setItem(key_name, this.encript(stringify_data));
    },
    decryptLocalStore(key_name) {
      if (localStorage.getItem(key_name)) {
        let data = this.decrypt(localStorage.getItem(key_name));
        return JSON.parse(data);
      } else return null;
    },

    /*convert date formte to dd-mm-yyyy*/
    display_date_formate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let new_date = `${day}-${month}-${year}`;
      return new_date;
    },

    // new axios methods

    GET_request(
      self,
      url,
      look_up_key,
      params,
      successHandler = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }

      if (headers == null) {
        headers = {
          token: sessionStorage.getItem("token"),
          "application-type": "web",
        };
      }

      axios_instance
        .get(url + look_up_key, {
          params: params,
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          if (successHandler != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnackBar(
                "error",
                response.data.detail,
                2000,
                true,
                true
              );
              console.log("GET then error: ", response);
            }
            successHandler(response);
          }
        })
        .catch(function (error) {
          if (!error.response) {
            if (error.message) {
              console.log("GET catch !error.response", error);
              self.showSnackBar("error", error, 2000, true, true);
            }
          } else {
            self.showSnackBar(
              "error",
              error.response.data.detail,
              2000,
              true,
              true
            );
            self.handle400(error.response.status);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            console.log("GET catch error.response:", error);
            if (failureHandler != null) {
              console.log("fail");
              failureHandler(error);
            }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    POST_request(
      self,
      url,
      params,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let access_token_businessloan = sessionStorage.getItem("access_token");
        let token = access_token_businessloan;

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .post(url, params, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnackBar(
                "error",
                response.data.detail,
                2000,
                true,
                true
              );
            }
            fnSuccessResponse(response);
            self.showSnackBar(
              "success",
              response.data.detail,
              2000,
              true,
              true
            );
          }
        })
        .catch(function (error) {
          if (!error.response) {
            console.log("POST catch !error.response", error);
            self.showSnackBar("error", error, 2000, true, true);
          } else {
            self.showSnackBar(
              "error",
              error.response.data.detail,
              2000,
              true,
              true
            );
            // console.log("Post catch error:", error.response.data);
            console.log("POST catch error.response:", error);
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle404(error.response.status);
            self.handle403(error.response.status);
            self.handle413(error.response.status);
            self.handle500(error.response.status);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    PATCH_request(
      self,
      url,
      look_up_key,
      body,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        let token = "";
        if (sessionStorage.getItem("token"))
          token = sessionStorage.getItem("token");

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .patch(`${url + look_up_key}/`, body, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnackBar(
                "error",
                response.data.detail,
                2000,
                true,
                true
              );
            }
            fnSuccessResponse(response);
            self.showSnackBar(
              "success",
              response.data.detail,
              2000,
              true,
              true
            );
          }
        })
        .catch(function (error) {
          if (!error.response) {
            console.log("PATCH catch !error.response", error);
            self.showSnackBar("error", error, 2000, true, true);
          } else {
            self.showSnackBar(
              "error",
              error.response.data.detail,
              2000,
              true,
              true
            );
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            self.handle413(error.response.status);
            console.log("PATCH catch error.response", error);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
    DELETE_request(
      self,
      url,
      look_up_key,
      fnSuccessResponse = null,
      headers = null,
      failureHandler = null,
      finallyHandler = null,
      isCancel = false
    ) {
      if (isCancel) {
        source.cancel();
        source = axios.CancelToken.source();
      }
      if (headers == null) {
        // let token = "";
        let access_token_businessloan = sessionStorage.getItem("access_token");
        let token = access_token_businessloan;
        // let token = access_token_businessloan;

        headers = {
          token: token,
          "application-type": "web",
        };
      }
      axios_instance
        .delete(`${url + look_up_key}/`, {
          headers: headers,
          cancelToken: isCancel ? source.token : "",
        })
        .then(function (response) {
          if (fnSuccessResponse != null) {
            if (response.status != 200 && response.status != 201) {
              self.showSnackBar(
                "error",
                response.data.detail,
                2000,
                true,
                true
              );
              console.log("DELETE error", response.data.detail);
            }
            fnSuccessResponse(response);
            self.showSnackBar(
              "success",
              response.data.detail,
              2000,
              true,
              true
            );
          }
        })
        .catch(function (error) {
          if (!error.response) {
            console.log("DELETE catch !error.response", error);
            self.showSnackBar("error", error, 2000, true, true);
          } else {
            self.showSnackBar(
              "error",
              error.response.data.detail,
              2000,
              true,
              true
            );
            self.handle401(error.response.status);
            self.handle440(error.response.status);
            self.handle403(error.response.status);
            self.handle500(error.response.status);
            console.log("DELETE catch error.response", error);
            if (failureHandler != null) {
              failureHandler();
            }
          }
        })
        // .finally(function(res) {
        .finally(function () {
          if (finallyHandler !== null) {
            finallyHandler();
          }
        });
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
