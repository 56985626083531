const URLS = {
  BASE_URL: "https://api.cashpo.in/api/", //production
  // BASE_URL: "https://staging.api.cashpo.in/api/", // staging

  SEND_OTP: "/web-panel/doc_login/",
  VERIFY_OTP: "/web-panel/doc_otp_verification/",
  CAREER: "/web-panel/career/",
  UPLOAD_BANK_STATEMENT: "/users/v2/upload_bank_statement/",
  REUPLOAD_BANK_STATEMENT: "/users/re_upload_documents/",
  FAQs: "/master/faq/",
  GET_APP_LINK: "/web-panel/get-app-link/",
  SUBMIT_CONTACT_US_FORM: "/web-panel/contact_us/",
  POST_BASIC_AND_PERSONAL_DETAIL:
    "/businessLoan/create_busniess_loan_borrower/",
  POST_KYC_DETAILS: "/businessLoan/upload_general_document/",
  POST_OTHER_DOCUMENTS: "/businessLoan/upload_others_document/",
  POST_SOLEPROPRIETORSHIP_OR_HUF_DATA:
    "/businessLoan/add_co_applicant_for_sole_properitorship_or_HUF/",
  POST_PARTNERSHIP_OR_LLP_DATA:
    "/businessLoan/add_co_applicant_partnership_or_LLP/",
  POST_COMPANY_DATA: "/businessLoan/add_co_applicant_company/",
  // UPDATE_PERSONAL_DETAILS:
  //   "/businessLoan/get_or_update_personal_deatils/BL174/",
  DELETE_OTHER_DOCUMENT: "/businessLoan/delete_document/",
  GET_BASIC_AND_PERSONAL_DETAILS:
    "/businessLoan/get_or_update_personal_deatils/",
  GET_DOCUMENT_DETAILS: "/businessLoan/get_list_of_general_doc/",
  GET_SOLEPROPRIETORSHIP_OR_HUF_DATA:
    "/businessLoan/get_or_update__sole_properitorship_or_HUF/",
  GET_PARTNERSHIP_OR_LLP_DATA:
    "/businessLoan/get_or_update__partnership_or_LLP/",
  GET_COMPANY_DATA: "/businessLoan/get_or_update__company/",
  SEND_BUSINESSLOAN_OTP: "/businessLoan/send_otp/",
  VERIFY_BUSINESSLOAN_OTP: "/businessLoan/verify_otp/",
  GET_PINCODE: "/businessLoan/get_pincode_list/",
  REDIRECT_TO_WEBSITE: "/businessLoan/get_pincode_list/mobile/",
  REGISTER_USER: "/businessLoan/register_user/",
  POST_BASIC_DETAILS: "/businessLoan/add_basic_deatils/",
  POST_PERSONAL_DETAILS: "/businessLoan/add_personal_deatils/",
  GET_BUSINESS_AMOUNT_LIST: "/master/get_business_amount_list/",

  //mutual funds
  GET_LAS_OTP: "lasapp/send_otp_in_las/",
  REGISTER_LAS_USER: "lasapp/register_in_las/",
  VERIFY_LAS_OTP: "lasapp/verify_otp_in_las/",
  REDIRECT_TO_URL: "lasapp/get_redirect_url/",
  GET_LAS_LOGIN_OTP: "lasapp/send_las_login_otp/",

  //mutual funds -- Abhi Loans -- v2
  REGISTER_AND_SEND_OTP: "/lasapp/abhiloans/register_and_send_otp/", // POST
  VERIFY_OTP_AND_GET_PROTFOLIO: "/lasapp/abhiloans/verify_otp/", // POST
  CREATE_LEAD_LINK: "/lasapp/abhiloans/create_lead_link/", // POST
  LAS_LOGIN: "/lasapp/las_user/login/", // POST
  LAS_VERIFY_OTP: "/lasapp/las_user/verify_otp/", // POST
  CHECK_FEATURE_ACTIVENESS: "/lasapp/check_feature_activeness/", // GET
  GET_REDIRECT_URL: "/lasapp/abhiloans/get_redirectional_url/", // GET parmas: mobile

  GET_LENDING_PARTNERS_LIST: "/master/get_active_landing_partners/",

  PL_SEND_OTP: "/web-panel/pl_webapp/send_otp/",
  PL_LOGIN: "/web-panel/pl_webapp/login/",
  PL_VERIFY_OTP: "/web-panel/pl_webapp/verify_otp/",
  PL_REGISTER_USER: "/web-panel/pl_webapp/register_user/",
  PL_APPLY_LOAN: "/web-panel/pl_webapp/apply_loan/", // POST
  PL_CHECK_STATUS: "/web-panel/pl_webapp/check_status/", // GET params: loan_id
  PL_CHECK_ORRISH_STATUS: "/web-panel/pl_webapp/check_orrish_status/", // GET params: loan_id

  GET_DLG_DATA: "/master/get_dlg_data/", // GET

  CREATE_MULTIPLE_LOAN_OFFER: "/loan/create_mutiple_loan_offers/", // POST body: loan_amount, tenure
  GET_AVAILABLE_OFFERS: "/loan/get_available_offers/", // GET
  PL_REGISTER_USER_V2: "/web-panel/pl_webapp/register_user_v2/",

  GET_BANKSATHI_COMPANY_DATA: "/nbfclink/get_banksathi_company_data/",
  AVAIL_LOAN_WITH_OFFER: "/loan/avail_loan_with_offer/" // POST body: offer_id,
};
export default URLS;
